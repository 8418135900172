import { Injectable } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';

import { CreateConversationModalComponent } from 'src/app/components/create-conversation-modal/create-conversation-modal.component';

@Injectable({
  providedIn: 'root',
})
export class GlobalCreateService {
  protected baseUrl: string;

  constructor(
    private navController: NavController,
    private modalCtrl: ModalController,
  ) {}

  async customer() {
    this.navController.navigateForward(`/dashboard/customers/add`);
  }

  async conversation(params?: Record<string, any>) {
    const modal = await this.modalCtrl.create({
      component: CreateConversationModalComponent,
      cssClass: 'modal-sm',
      componentProps: params,
    });

    modal.present();
  }

  async broadcast() {
    this.navController.navigateForward(`/slack-message/new`);
  }

  async connectChannels() {
    this.navController.navigateForward('/customers/new');
  }
}
