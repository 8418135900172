export const CONVERSATION_LINK_TYPES = {
  RELATED_TO: 'relatedTo',
  BLOCKS: 'blocks',
  TRACKS: 'tracks',
} as const;

export type ConversationLinkType = (typeof CONVERSATION_LINK_TYPES)[keyof typeof CONVERSATION_LINK_TYPES];

export const CONVERSATION_LINK_TYPES_UNIDIRECTIONAL = {
  RELATED_TO: 'relatedTo',
  BLOCKS: 'blocks',
  BLOCKED_BY: 'blockedBy',
  TRACKS: 'tracks',
  TRACKED_BY: 'trackedBy',
} as const;

export const CONVERSATION_LINK_TYPES_UNIDIRECTIONAL_MAP = {
  [CONVERSATION_LINK_TYPES.RELATED_TO]: ['relatedTo', 'relatedTo'],
  [CONVERSATION_LINK_TYPES.BLOCKS]: ['blocks', 'blockedBy'],
  [CONVERSATION_LINK_TYPES.TRACKS]: ['tracks', 'trackedBy'],
} as const;

export type ConversationLinkTypeUnidirectional =
  (typeof CONVERSATION_LINK_TYPES_UNIDIRECTIONAL)[keyof typeof CONVERSATION_LINK_TYPES_UNIDIRECTIONAL];

export interface ConversationLink {
  id: string;
  conversationId: string;
  linkedConversationId: string;
  type: string;
}

export const CONVERSATION_LINK_TYPES_UNIDIRECTIONAL_LABELS = {
  [CONVERSATION_LINK_TYPES_UNIDIRECTIONAL.RELATED_TO]: 'Related to',
  [CONVERSATION_LINK_TYPES_UNIDIRECTIONAL.BLOCKS]: 'Blocks',
  [CONVERSATION_LINK_TYPES_UNIDIRECTIONAL.TRACKS]: 'Tracks',
  [CONVERSATION_LINK_TYPES_UNIDIRECTIONAL.TRACKED_BY]: 'Tracked by',
  [CONVERSATION_LINK_TYPES_UNIDIRECTIONAL.BLOCKED_BY]: 'Blocked by',
} as const;

export const CONVERSATION_LINK_TYPES_UNIDIRECTIONAL_ICONS = {
  [CONVERSATION_LINK_TYPES_UNIDIRECTIONAL.RELATED_TO]: 'code-outline',
  [CONVERSATION_LINK_TYPES_UNIDIRECTIONAL.BLOCKS]: 'caret-forward-circle-outline',
  [CONVERSATION_LINK_TYPES_UNIDIRECTIONAL.TRACKS]: 'magnet-outline',
  [CONVERSATION_LINK_TYPES_UNIDIRECTIONAL.TRACKED_BY]: 'magnet-outline',
  [CONVERSATION_LINK_TYPES_UNIDIRECTIONAL.BLOCKED_BY]: 'caret-back-circle-outline',
} as const;
